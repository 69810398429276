import { roles } from '@/enums'
import { useCurrentRoleStore } from '~/store/currentRole'

export default defineNuxtRouteMiddleware((to, _from) => {
  // Only allow admins to go to admin pages when they enter the right code.
  // const runtimeConfig = useRuntimeConfig()
  // const { dashboardSecurityCode } = runtimeConfig.public
  // if (!_from || _from.query?.key !== dashboardSecurityCode) {
  //   return navigateTo('/')
  // }
  const user = useSupabaseUser()
  const currentRoleStore = useCurrentRoleStore()
  const schoolId = currentRoleStore.getCurrentRole().schoolId
  const isAdmin = user.value?.user_metadata.schoolRoles?.find(
    (schoolRole: SchoolRole) =>
      schoolRole.schoolId === schoolId && schoolRole.role === roles.ADMIN,
  )
  if (!isAdmin) {
    return navigateTo('/')
  }
})
